.contact-form {
	border-radius: 5px;
	max-width: 500px;
	height: auto;
	padding-top: 5px;
	padding-bottom: 5px;
	animation: bounce 1.5s infinite;
	float: right;
	width: 100%;
}
.contact-form label {
	color: white;
}
.entry-field {
	background-color: rgba(255, 255, 255, 0.25);
	color: rgb(10, 10, 10);
	margin: 10px;
}
.form-entry {
	display: block;
	margin: 10px;
	padding: 10px;
	border-radius: 2px;
	border: none;
	transition: all 0.5s ease 0s;
}
.send {
	width: 100px;
	color: white;
	background-color: #303131;
	font-size: 20px;
	z-index: 1;
}
.message-area {
	padding: 30px 0;
	width: 100%;
	font-size: 16px;
}
.contact-form label svg {
	margin-right: 11px;
	margin-bottom: -3px;
}
.label-field {
	margin-bottom: 3px;
}
.label-style {
	text-align: left;
}
.input-field-style {
	width: 100%;
	padding: 4px;
	font-size: 15px;
	font-weight: 200;
	background: rgba(255, 255, 255, 0.4);
	border: none;
	color: black;
	font-size: 16px;
	font-weight: bold;
}
.submit-button-style {
	padding: 5px;
	border: none;
	cursor: pointer;
}
.submit-button-style:disabled {
	background-color: white;
	color: black;
	cursor: not-allowed;
}
.submit-btn {
	display: flex;
	flex-direction: row-reverse;
}

.validation-msg {
	color: #f80000;
	background: white;
	font-size: 12px;
	font-weight: bold;
	width: max-content;
	padding: 2px 4px;
	margin-top: 2px;
	border: 1px solid #f80000;
}
.form-style {
	box-shadow: 0 30px 60px 0 rgb(90 116 148 / 40%);
	border-top: 5px solid #404040;
	background: rgba(255, 255, 255, 0.25);
	border-bottom: 5px solid #404040;
}
.form-heading {
	font-size: 24px;
    font-weight: bold;
    margin-bottom: 25px;
}
.horizontal-line {
	background-color: black;
	height: 5px;
	border: 1px solid black;
}
