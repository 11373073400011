.contactus-container {
	width: 90%;
	margin: 0 auto;
	overflow: auto;
	box-sizing: border-box;
	color: white;
	overflow-wrap: break-word;
	display: inline-block;
	margin-inline: 5%;
}
.form-wrap {
	overflow: auto;
}
.contact-us-info {
	float: left;
	width: 50%;
}
.contact-btn {
	background: none;
	padding: 5px 18px;
	margin-right: 3rem;
	margin-bottom: 20px;
	border: 1px solid white;
	color: white;
	cursor: pointer;
}
.show-contact-us {
	display: flex;
	flex-direction: row-reverse;
}
.contact-us-editorial-text {
	background: rgba(0, 0, 0, 0) !important;
}
@media screen and (min-width: 280px) and (max-width: 576px) {
	.contact-us-info {
		float: left;
		width: 100%;
	}
}
