*{
    margin:0;
    padding:0;
    box-sizing: border-box;

}
.each-addr:not(:last-child) {
    width: max-content;
    border-bottom: 1px solid rgb(255, 255, 255);
    margin-bottom: 20px;
    padding-bottom: 20px;
}
.location-icon {
    float: left;
    margin-top: 6px;
}
.address {
    padding-left: 26px;
    font-size: 20px;
}
.address-heading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 25px;
}

@media screen and (min-width: 280px) and (max-width: 960px) {  
    .each-addr:not(:last-child) {
        width: 280px;
    }
    .each-addr {
        white-space: pre-wrap;
        width: 280px;
        word-wrap: break-word;
    }
    .address {
        font-size: 18px;
    }
}